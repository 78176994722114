import React from 'react';
import { ICrossSell } from '../../../types/properties';
import style from './crossSellUnit.scss';
import * as helper from '../../helper/html-helper';
import { isMobile } from 'react-device-detect';

export const CrossSellUnit = (props: ICrossSell) => {
    function taxMode(): string {
        if (props.priceMode.length > 0) {
            const realValue = props.priceMode === 'Net' ? props.localization.excludeText : props.localization.includeText;
            return '(' + helper.decodeHTML(realValue) + ')';
        } else
            return null;
    }

    function showPriceText(): string {
        if (props.priceMode.length > 0) {
            let realValue = props.bestPrice.minimalPrice.netValue;
            if (!props.isGlobal) {
                realValue = props.priceMode === 'Net' ? props.bestPrice.minimalPrice.netValue : props.bestPrice.minimalPrice.grossValue;
            }
            let priceText = '';
            if (isMobile) {
                priceText = props.bestPrice.minimalPrice.prependCurrencySymbolMobile ?
                    `${props.bestPrice.minimalPrice.currencySymbolMobile} ${realValue}` :
                    `${realValue} ${props.bestPrice.minimalPrice.currencySymbolMobile}`;
            } else {
                priceText = props.bestPrice.minimalPrice.prependCurrencySymbolWeb ?
                    `${props.bestPrice.minimalPrice.currencySymbolWeb} ${realValue}` :
                    `${realValue} ${props.bestPrice.minimalPrice.currencySymbolWeb}`;
            }

            const priceType = props.bestPrice.minimalPrice.priceType === 'from' ?
                props.localization.priceFrom : props.localization.priceOnly;
            return helper.decodeHTML(priceType + ' ' + priceText);
        } else
            return null;
    }

    function quantityString(): string {
        return helper.decodeHTML(props.localization.quantityFrom) + ' ' + props.bestPrice.minimalPrice.quantity;
    }

    function comparePriceText(): string {
        if (props.bestPrice.comparisonPrice && props.priceMode.length > 0) {
            const realValue = props.priceMode === 'Net' ?
                props.bestPrice.comparisonPrice.netValue : props.bestPrice.comparisonPrice.grossValue;
            let priceValue = '';
            if (isMobile) {
                priceValue = props.bestPrice.comparisonPrice.prependCurrencySymbolMobile ?
                    `${props.bestPrice.comparisonPrice.currencySymbolMobile} ${realValue}` :
                    `${realValue} ${props.bestPrice.comparisonPrice.currencySymbolMobile}`;
            } else {
                priceValue = props.bestPrice.comparisonPrice.prependCurrencySymbolWeb ?
                    `${props.bestPrice.comparisonPrice.currencySymbolWeb} ${realValue}` :
                    `${realValue} ${props.bestPrice.comparisonPrice.currencySymbolWeb}`;
            }

            return helper.decodeHTML(props.localization.comparePrice + props.localization.colon +
                ' ' + priceValue + '/' + props.bestPrice.comparisonPrice.unit);
        } else
            return null;
    }

    function detailsPageLinkWithItemOrign(): string {
        const url = new URL(props.detailsPageLink);

        // short info about recommendationSource:
        // recommendationSource is manualMaintenance -> CROSS
        // recommendationSource is articleRecommendation -> CROSSRECO

        if(props.recommendationSource === 'manualMaintenance')
            url.searchParams.append('itemOrigin', 'CROSS/' + props.rootNavKey);
        else 
            url.searchParams.append('itemOrigin', 'CROSSRECO/' + props.rootNavKey);
        
        return url.toString();
    }

    function getAltTagValue(): string {
        return props.localization.mainView + ', ' + props.title
    }

    return (
        <a className={style.cross_sell_unit} href={detailsPageLinkWithItemOrign()} data-testid={'detailsPageLinkWithItemOrign'}>
            <div className={style.image}>
                <img src={helper.parserImageUrl(props.mainImageSource, props.cdnHost)} 
                alt={getAltTagValue()}/>
            </div>
            <div className={style.title}>
                {props.title}
            </div>
            {
                props.isGlobal ?
                    <div className={style.price} data-ge-price data-testid={'price_text'}>
                        {showPriceText()}
                    </div> :
                    <div className={style.price} data-testid={'price_text'}>
                        {showPriceText()}
                    </div>
            }
            {
                props.bestPrice.comparisonPrice &&
                <div className={style.compare_price}>
                    {comparePriceText()}
                </div>
            }
            {
                !props.isGlobal &&
                <div className={style.tax_mode} data-testid={'tax_mode'}>
                    {taxMode()}
                </div>
            }
            {props.bestPrice.minimalPrice.priceType === 'from' &&
                <div data-testid={'quantity_text'} className={style.quantity}>
                    {quantityString()}
                </div>
            }
        </a>
    );
}
