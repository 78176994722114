import * as React from 'react';
import ModalWrapper from '../modalwrapper/modalwrapper';
import classNames from 'classnames';
import style from '../modal.scss'
import * as helper from '../helper/html-helper';

function MaxAmountModal(props: { onIsOpenChange, 
    maxQuantityOrderTitle: string, errorMessage: string }) {

    return (
        <ModalWrapper title={helper.decodeHTML(props.maxQuantityOrderTitle)} onClose={props.onIsOpenChange}>
                <div className={style.inner_content}>
                    <div className={style.modal_body}>
                        {props.errorMessage}
                    </div>
                </div>
                <div className={style.modal_footer}>
                    <div
                        className={classNames(style.button_container, style.red)} onClick={props.onIsOpenChange}>
                        <div className={style.button_label}>ok</div>
                    </div>
                </div>
            </ModalWrapper>
)}

export default MaxAmountModal;
