import * as React from 'react';
import ModalWrapper from '../modalwrapper/modalwrapper';
import classNames from 'classnames';
import style from '../modal.scss'
import { ILocalization } from '../../types/properties';

function SelfServiceBanForPrivateModal(props: { onIsOpenChange, localization: ILocalization }) {
    return (
        <>
            < ModalWrapper title={props.localization.confirmSelfServiceBanMessageHeadline} onClose={props.onIsOpenChange}>
                <div className={style.inner_content}>
                    <div className={style.modal_body}>
                        <div className={style.self_service_body}>
                            <div dangerouslySetInnerHTML={{__html: props.localization.confirmSelfServiceBanMessage1}}/>
                        </div>
                    </div>
                </div>
                <div className={classNames(style.modal_footer, style.self_service_no_border)}>
                    <div className={classNames(style.button_container, style.red)} onClick={props.onIsOpenChange}>
                        <div className={style.button_label}>
                                OK
                            </div>
                    </div>
                </div>
            </ModalWrapper>
        </>
    )
}

export default SelfServiceBanForPrivateModal;
