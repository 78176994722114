/* eslint-disable no-undef*/
import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import ModalContainer from './modalcontainer/modalcontainer';

global.React = React;
global.ReactDOM = ReactDOM;
global.ModalContainer = ModalContainer;
global.ReactDOMServer = ReactDOMServer;
