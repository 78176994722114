import * as React from 'react';
import CrossIcon from '../../Assets/svg/crossIcon';
import styles from '../modal.scss';
// eslint-disable-next-line react/prop-types
function ModalWrapper({ children, title, onClose }) {

    return (
        <>
            <div className={styles.modal_wrapper}>
                <div className={styles.modal_container}>
                    <div className={styles.modal_content}>
                        <div className={styles.modal_header}>
                            <span className={styles.modal_title}>{title}</span>
                            <div className={styles.close_button} data-testid="close_button_header" onClick={onClose} >
                                <CrossIcon />
                            </div>
                        </div>
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ModalWrapper;
