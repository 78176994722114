export function decodeHTML(value: string): string {
    return value.replace(/&#(\d+);/g, (match, dec) => {
        return String.fromCharCode(dec);
    });
}

export function parserImageUrl(imageUrl: string, cdnHost: string): string {
    const host: string = window.location.hostname;
    const isPreview: boolean = host.includes('preview');
    let index: number = imageUrl.indexOf('product/');
    if (index < 0)
        index = imageUrl.indexOf('documents/');
    const dataPath: string = imageUrl.substr(index);
    const imagePath: string = isPreview ? 'images_preview' : 'images';

    return `${cdnHost}assets/pdp/${imagePath}/Original/${dataPath}`;
}
