import * as React from 'react';
import { ModalBody } from './modalbody/modalBody';
import { ModalBodyForLC } from './modalbody/modalBodyForLC';
import { IBasketModal } from '../../types/properties';
import ModalWrapper from '../modalwrapper/modalwrapper';
import ModalFooter from './modalfooter/modalFooter';
import CrossSelling from '../basketmodal/crossSelling/crossSelling';
import style from '../modal.scss';

export default class BasketModal extends React.Component<IBasketModal> {
    constructor(props: IBasketModal) {
        super(props);
        this.handleRedirect = this.handleRedirect.bind(this);
    }

    render() {
        return (
            <>
                {
                    (this.props.data || this.props.dataForLC) &&
                    < ModalWrapper title={this.props.localization.basket} onClose={this.props.onIsOpenChange}>
                        <div className={style.inner_content}>
                            {
                                this.props.dataForLC && this.props.data === null ?
                                    <ModalBodyForLC
                                        model={this.props.dataForLC}
                                        localization={this.props.localization}
                                        cdnHost={this.props.cdnHost} /> :
                                    <ModalBody
                                        model={this.props.data}
                                        localization={this.props.localization}
                                        cdnHost={this.props.cdnHost} />
                            }
                            {
                                this.props.amsResponse?.crossSellings?.length > 0 &&
                                <CrossSelling
                                    amsResponse={this.props.amsResponse}
                                    localization={this.props.localization}
                                    priceMode={this.props.priceMode}
                                    cdnHost={this.props.cdnHost}
                                />
                            }
                        </div>
                        <ModalFooter
                                onClose={this.props.onIsOpenChange}
                                onContinue={this.handleRedirect}
                                localization={this.props.localization}
                                isLC={this.props.dataForLC && this.props.data === null}
                                smakeId={this.props.dataForLC?.smakeId}
                        />
                    </ModalWrapper>
                }
            </>
        )
    }

    private handleRedirect() {
        this.props.onIsOpenChange(true);
        location.href = this.props.localization.basketLink;
    }
}
