import React from 'react';

/* eslint-disable */
const IconArrowMediumRight = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.79991" height="37.80121" viewBox="0 0 13.79991 37.80121">
        <path d="M.899,37.80121A.90053.90053,0,0,1,.151,36.4018l11.66748-17.501L.151,1.39984A.9.9,0,0,1,1.649.4018l12,18a.90064.90064,0,0,1,0,.998l-12,18A.899.899,0,0,1,.899,37.80121Z"/>
    </svg>
);

export default IconArrowMediumRight;
