import React from 'react';

/* eslint-disable */
const IconArrowMediumLeft = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.79991" height="37.80053" viewBox="0 0 13.79991 37.80053">
        <path d="M12.90088,37.80053a.899.899,0,0,1-.75-.40137l-12-18a.9007.9007,0,0,1,0-.998l12-18a.9.9,0,0,1,1.498.998L1.98145,18.90014l11.66748,17.501a.90054.90054,0,0,1-.748,1.39942Z"/>
    </svg>
);

export default IconArrowMediumLeft;
