import * as React from 'react';
import ModalWrapper from '../modalwrapper/modalwrapper';
import classNames from 'classnames';
import style from '../modal.scss'
import { ILocalization } from '../../types/properties';
import * as helper from '../helper/html-helper';

function SelfServiceBanModal(props: { onIsOpenChange, onConfirm, localization: ILocalization }) {
    return (
        <>
            < ModalWrapper title={props.localization.confirmSelfServiceBanMessageHeadline} onClose={props.onIsOpenChange}>
                <div className={style.inner_content}>
                    <div className={style.modal_body}>
                        <div className={style.self_service_body}>
                            <div dangerouslySetInnerHTML={{__html: props.localization.confirmSelfServiceBanMessage1}}/>
                            <div dangerouslySetInnerHTML={{__html: props.localization.confirmSelfServiceBanMessage2}}/>
                            <div dangerouslySetInnerHTML={{__html: props.localization.confirmSelfServiceBanMessage3}}/>
                        </div>
                    </div>
                </div>
                <div className={style.modal_footer}>
                    <div className={style.button_container} onClick={props.onIsOpenChange}>
                        <div className={style.button_label}>
                            {helper.decodeHTML(props.localization.confirmSelfServiceBanMessageButtonNotOk)}
                        </div>
                    </div>
                    <div className={classNames(style.button_container, style.red)} onClick={props.onConfirm}>
                        <div className={style.button_label}>
                            {helper.decodeHTML(props.localization.confirmSelfServiceBanMessageButtonOk)}
                        </div>
                    </div>
                </div>
            </ModalWrapper>
        </>
    )
}

export default SelfServiceBanModal;
