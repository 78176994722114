import { IActionControlEventData } from '../action-control';
import axios from 'axios';

export class ActionHelper {
    private static _instance: ActionHelper;

    private actionControlAjaxTarget: string;
    private isEnabled: boolean;
    private isInitialized: boolean;
    private isSending: boolean;
    private timeoutHandle: ReturnType<typeof setTimeout>;
    private actions: Action[] = [];
    private pageViewLogDataId: string;

    private static SendEveryNumberOfSeconds = 10;

    static get instance() {
        return this._instance || (this._instance = new this());
    }

    private init(): void {
        if (this.isInitialized) return;
        this.isInitialized = true;

        const queryResult = document.querySelector('meta[name = "action-control-ajax-target"]');

        if(queryResult)
            this.actionControlAjaxTarget = queryResult.getAttribute('content');

        this.pageViewLogDataId = document.documentElement.getAttribute('data-page-view-log-data-id');
        if (this.pageViewLogDataId && typeof (this.actionControlAjaxTarget) != 'undefined' && this.actionControlAjaxTarget)
            this.isEnabled = true;
    }

    public trackActionControlEventCallback(eventData: IActionControlEventData): void {
        if (!this.isInitialized)
            this.init();
        if (this.isEnabled && eventData.actionSubject && eventData.actionType) {
            this.actions.push(new Action(eventData.actionSubject, eventData.actionType, this.pageViewLogDataId));
            this.sendActions(eventData.forceSend);
        }
    }

    private setupTimeout(): void {
        clearTimeout(this.timeoutHandle);
        this.timeoutHandle = setTimeout(
            () => this.sendActions(false),
            ActionHelper.SendEveryNumberOfSeconds * 1000);
    }

    private sendActions(forceSend: boolean): void {
        if ((forceSend || !this.isSending) && this.actions.length > 0) {
            this.isSending = true; // for not to be calling server multiple times
            const actionsToSend: Action[] = this.actions;
            this.actions = []; // let user queue more actions in the meantime

            axios({
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                url: this.actionControlAjaxTarget,
                data: JSON.stringify({ Actions: actionsToSend })
                })
                .then(() => {
                    this.isSending = false;
                    this.setupTimeout();
                })
                .catch(() => {
                    this.actions = actionsToSend.concat(this.actions);
                });
        }
        else if (!this.isSending) {
            this.setupTimeout();
        }
    }
}

class Action {
    private Subject: string;
    private ActionType: string;
    private Time: string;
    private PageViewLogDataId: string;

    constructor(subject: string, actionType: string, pageViewLogDataId: string) {
        this.Subject = subject;
        this.ActionType = actionType;
        this.Time = new Date().toJSON();
        this.PageViewLogDataId = pageViewLogDataId;
    }
}
