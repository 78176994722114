import React from 'react';
import style from '../../modal.scss';
import classNames from 'classnames';
import BasketIcon from '../../../Assets/svg/basketIcon';
import { IModalFooter } from '../../../types/properties';
import * as helper from '../../helper/html-helper';
function ModalFooter(props: IModalFooter) {
    function closeButtonClick() {
        if (props.isLC) {
            props.onClose();
            if (typeof window != 'undefined' && window.shell)
                window.shell.publishTo('ESPP.BasketModal.OpenLogoCreator', { smakeId: props.smakeId });
        } else {
            props.onClose();
        }
    }

    return (
        <div className={style.modal_footer}>
            <div className={classNames(style.button_container, style.grey)}
                data-testid='show_shopping_basket_button' onClick={props.onContinue}>
                <div className={style.button_icon}>
                    <BasketIcon />
                </div>
                <div className={style.button_label}>
                    {helper.decodeHTML(props.localization.goToBasket)}
                </div>
            </div>
            <div data-testid='continue_shopping_button' className={classNames(style.button_container, style.red)} onClick={closeButtonClick}>
                <div className={style.button_label}>
                    {
                        props.isLC ?
                            helper.decodeHTML(props.localization.continuePersonalisation) :
                            helper.decodeHTML(props.localization.continueShopping)

                    }
                </div>
            </div>
        </div>);
}

export default ModalFooter;
