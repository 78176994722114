/* eslint-disable max-len */
import React from 'react';

function BasketIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25.6"
            height="23"
            viewBox="0 0 25.6 23"
        >
            <path d="M21,15.61H7.61a1,1,0,0,1-1-.8L4,2H1A1,1,0,0,1,1,0H4.83a1,1,0,0,1,1,.8L8.43,13.6H20.25l3-9.57H11.65a1,1,0,0,1,0-2H24.59a1,1,0,0,1,.81.41,1,1,0,0,1,.15.89L22,14.9A1,1,0,0,1,21,15.61Z" />
            <path d="M18.29,23a2.84,2.84,0,1,1,2.84-2.84A2.84,2.84,0,0,1,18.29,23Zm0-4.12a1.28,1.28,0,1,0,1.27,1.28A1.28,1.28,0,0,0,18.29,18.88Z" />
            <path d="M10.62,23a2.84,2.84,0,1,1,2.84-2.84A2.84,2.84,0,0,1,10.62,23Zm0-4.12a1.28,1.28,0,1,0,1.27,1.28A1.28,1.28,0,0,0,10.62,18.88Z" />
        </svg>
    );
}

export default BasketIcon;
