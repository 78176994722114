import React from 'react';
import { ICrossSelling } from '../../../types/properties';
import style from './crossSelling.scss';
import defaultStyles from '../../../styles/default.scss';
import { CrossSellUnit } from './crossSellUnit';
import ScrollContainer from 'react-indiana-drag-scroll';
import ArrowMediumLeft from '../../../Assets/svg/icon_arrow_medium_left';
import ArrowMediumRight from '../../../Assets/svg/icon_arrow_medium_right';

import * as helper from '../../helper/html-helper';
import classNames from 'classnames';

interface ICrossSellingState {
    showLeftArrow: boolean,
    showRightArrow: boolean,
    count: number;
}

export default class CrossSelling extends React.Component<ICrossSelling, ICrossSellingState> {
    private swipeContentRef = React.createRef<HTMLDivElement>();
    private scrollContentRef = React.createRef<HTMLDivElement>();
    private leftArrowRef = React.createRef<HTMLDivElement>();
    private rightArrowRef = React.createRef<HTMLDivElement>();
    private currentShowCount: number;
    private currentShowWidth: number;
    private maxWidth: number;
    private unitWidth = parseInt(defaultStyles.defaultCrossSellUnitWidth) + 10;
    private mobileContainer = document.querySelector('.page-container');

    constructor(props: ICrossSelling) {
        super(props);
        this.state = {
            showLeftArrow: false,
            showRightArrow: props.amsResponse.crossSellings.length > 3,
            count: 3
        };
        this.detectScroll = this.detectScroll.bind(this);
        this.onLeftClick = this.onLeftClick.bind(this);
        this.onRightClick = this.onRightClick.bind(this);
    }

    componentDidMount() {
        this.detectShowCount();
        // last unit do not have margin
        this.maxWidth = this.props.amsResponse.crossSellings.length * this.unitWidth - 10;
        if (typeof window != 'undefined')
            window.addEventListener('resize', this.detectShowCount.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.detectShowCount.bind(this));
    }

    private detectShowCount() {
        let showCount = 3;
        this.currentShowWidth = 470;
        if (this.scrollContentRef.current) {
            const width = this.scrollContentRef.current.clientWidth;
            showCount = Math.floor(width / this.unitWidth);
            this.currentShowWidth = this.scrollContentRef.current.clientWidth;
        }

        this.currentShowCount = showCount;
        this.setState({ count: this.currentShowCount });
    }

    private moveScrollRightClick(nextCount: number) {
        let delta = nextCount * this.unitWidth - this.currentShowWidth;
        if (delta - this.scrollContentRef.current.scrollLeft < this.unitWidth)
            delta = delta + this.unitWidth;
        this.scrollContentRef.current.scroll({
            left: delta,
            behavior: 'smooth'
        })
    }

    private onRightClick() {
        if (!this.scrollContentRef.current)
            return

        let nextCount = this.state.count + 1;
        const conditionRightClick = nextCount <= this.props.amsResponse.crossSellings.length && nextCount >= this.currentShowCount;

        if (this.mobileContainer && conditionRightClick)
            this.moveScrollRightClick(nextCount);
        else {
            for (let i = this.state.count; i < this.state.count + 2; i++) {
                if (conditionRightClick)
                    nextCount += 1;
                else
                    break;
            }
            this.moveScrollRightClick(nextCount);
        }
    }

    private moveScrollLeftClick(nextCount: number) {
        let delta = (nextCount - this.currentShowCount) * this.unitWidth;
        if (this.scrollContentRef.current.scrollLeft - delta < this.unitWidth)
            delta = delta - this.unitWidth;
        this.scrollContentRef.current.scroll({
            left: delta,
            behavior: 'smooth'
        })
    }

    private onLeftClick() {
        if (!this.scrollContentRef.current)
            return

        let nextCount = this.state.count - 1;
        const conditionLeftClick = nextCount >= this.currentShowCount;

        if (this.mobileContainer && conditionLeftClick)
            this.moveScrollLeftClick(nextCount);
        else {
            for (let i = this.state.count; i > this.state.count - 2; i--) {
                if (conditionLeftClick)
                    nextCount -= 1;
                else
                    break;
            }

            this.moveScrollLeftClick(nextCount);
        }
    }

    private detectScroll() {
        if (this.swipeContentRef.current && this.leftArrowRef.current && this.rightArrowRef.current) {
            const content = this.swipeContentRef.current.getElementsByClassName('bm-slider')[0];
            const shouldShowLeft = content.scrollLeft >= 1;
            const shouldShowRight = content.scrollLeft <= content.scrollWidth - content.clientWidth - 1;

            let currentCount = this.currentShowCount;
            if (content.scrollLeft >= 1) {
                if (content.scrollLeft + this.currentShowWidth === this.maxWidth)
                    currentCount = this.props.amsResponse.crossSellings.length;
                else
                    currentCount = Math.floor((content.scrollLeft + this.currentShowWidth) / this.unitWidth);
            }

            this.setState({
                showLeftArrow: shouldShowLeft,
                showRightArrow: shouldShowRight,
                count: currentCount
            });
        }
    }

    render() {
        return (
            <div className={style.cross_sell}>
                <div className={style.cross_sell_header}>
                    <div className={style.cross_sell_title}>
                        {helper.decodeHTML(this.props.localization.couldBeInteresting)}
                    </div>
                    {this.props.amsResponse.crossSellings.length > 3 &&
                        <div className={style.cross_sell_pager}>
                            {this.state.count.toString() + ' / ' + this.props.amsResponse.crossSellings.length.toString()}
                        </div>
                    }
                </div>
                <div ref={this.swipeContentRef} className={style.slider_wrapper}>
                    <div
                        ref={this.leftArrowRef}
                        className={classNames(style.left_arrow, { [style.hidden]: !this.state.showLeftArrow })}
                        data-testid='bm-left_arrow'
                        onClick={this.onLeftClick}
                    >
                        <ArrowMediumLeft />
                    </div>
                    <ScrollContainer
                        innerRef={this.scrollContentRef}
                        vertical={false}
                        className={style.slider}
                        onEndScroll={this.detectScroll}
                    >
                        <div className={style.cross_sell_content}>
                            {this.props.amsResponse.crossSellings.map((crossSell, index) => {
                                return <CrossSellUnit
                                    key={index}
                                    detailsPageLink={crossSell.detailsPageLink}
                                    mainImageSource={crossSell.mainImageSource}
                                    title={crossSell.title}
                                    bestPrice={crossSell.bestPrice}
                                    salesArticleNumber={crossSell.salesArticleNumber}
                                    localization={this.props.localization}
                                    priceMode={this.props.priceMode}
                                    cdnHost={this.props.cdnHost}
                                    rootNavKey={this.props.amsResponse.rootCategory}
                                    recommendationSource={crossSell.recommendationSource}
                                    isGlobal={crossSell.isGlobal}
                                />
                            })}
                        </div>
                    </ScrollContainer>
                    <div
                        ref={this.rightArrowRef}
                        className={classNames(style.right_arrow, { [style.hidden]: !this.state.showRightArrow })}
                        onClick={this.onRightClick}
                        data-testid='bm-right_arrow'
                    >
                        <ArrowMediumRight />
                    </div>
                </div>
            </div>);
    }

}
